<template>
  <div>

    <v-card v-if="editor">
      <v-toolbar dense flat>
        <v-btn-toggle
            multiple
        >
          <WysiwygEditorToolbarBtn :active="editor.isActive('heading', { level: 1 })" btn-txt="H1"
                                   tooltip="H1-Überschrift"
                                   @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"/>

          <WysiwygEditorToolbarBtn :active="editor.isActive('heading', { level: 2 })" btn-txt="H2"
                                   tooltip="H2-Überschrift"
                                   @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"/>

          <WysiwygEditorToolbarBtn :active="editor.isActive('heading', { level: 3 })" btn-txt="H3"
                                   tooltip="H3-Überschrift"
                                   @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"/>

          <WysiwygEditorToolbarBtn :active="editor.isActive('paragraph')" icon="mdi-format-paragraph"
                                   tooltip="Fließtext" @click="editor.chain().focus().setParagraph().run()"/>
          <WysiwygEditorToolbarBtn :active="editor.isActive('blockquote')" icon="mdi-comment-quote"
                                   tooltip="Zitat" @click="editor.chain().focus().toggleBlockquote().run()"/>
        </v-btn-toggle>
        <v-divider
            class="mx-4"
            vertical
        ></v-divider>
        <v-btn-toggle
            multiple
        >
          <WysiwygEditorToolbarBtn :active="editor.isActive('bold')" icon="mdi-format-bold"
                                   tooltip="Fett" @click="editor.chain().focus().toggleBold().run()"/>
          <WysiwygEditorToolbarBtn :active="editor.isActive('italic')" icon="mdi-format-italic"
                                   tooltip="Kursiv" @click="editor.chain().focus().toggleItalic().run()"/>
          <WysiwygEditorToolbarBtn :active="editor.isActive('underline')" icon="mdi-format-underline"
                                   tooltip="Unterstrichen" @click="editor.chain().focus().toggleUnderline().run()"/>
          <WysiwygEditorToolbarBtn :active="editor.isActive('strike')" icon="mdi-format-strikethrough"
                                   tooltip="Durchgestrichen" @click="editor.chain().focus().toggleStrike().run()"/>
          <WysiwygEditorToolbarBtn :active="editor.isActive('subscript')" icon="mdi-format-subscript"
                                   tooltip="Tiefgestellt"
                                   @click="editor.chain().focus().toggleSubscript().run()"/>
          <WysiwygEditorToolbarBtn :active="editor.isActive('superscript')" icon="mdi-format-superscript"
                                   tooltip="Hochgestellt"
                                   @click="editor.chain().focus().toggleSuperscript().run()"/>
          <WysiwygEditorToolbarBtn :active="colorDialog" icon="mdi-palette"
                                   tooltip="Farbe" @click="openColorPicker"/>
          <WysiwygEditorToolbarBtn :active="colorDialog" icon="mdi-format-color-fill"
                                   tooltip="Texthintergrund" @click="openHighlightPicker"/>
          <v-dialog v-model="colorDialog" width="300">
            <v-color-picker
                v-model="pickerColor"
                :swatches="swatches"
                dot-size="25"
                hide-inputs
                show-swatches
                swatches-max-height="200"
                @input="updateColor"
            ></v-color-picker>
          </v-dialog>
          <v-dialog v-model="highlightDialog" width="300">
            <v-color-picker
                v-model="highlightColor"
                :swatches="swatches"
                dot-size="25"
                hide-inputs
                show-swatches
                swatches-max-height="200"
                @input="updateHighlightColor"
            ></v-color-picker>
          </v-dialog>
        </v-btn-toggle>
        <v-divider
            class="mx-4"
            vertical
        ></v-divider>
        <v-btn-toggle
            multiple
        >
          <WysiwygEditorToolbarBtn :active="editor.isActive({ textAlign: 'left' })" icon="mdi-format-align-left"
                                   tooltip="Linksbündig" @click="editor.chain().focus().setTextAlign('left').run()"/>
          <WysiwygEditorToolbarBtn :active="editor.isActive({ textAlign: 'center' })" icon="mdi-format-align-center"
                                   tooltip="Zentriert" @click="editor.chain().focus().setTextAlign('center').run()"/>
          <WysiwygEditorToolbarBtn :active="editor.isActive({ textAlign: 'right' })" icon="mdi-format-align-right"
                                   tooltip="Rechtsbündig" @click="editor.chain().focus().setTextAlign('right').run()"/>


        </v-btn-toggle>

        <v-divider
            class="mx-4"
            vertical
        ></v-divider>
        <v-btn-toggle
            multiple
        >
          <WysiwygEditorToolbarBtn :active="editor.isActive('bulletList')" icon="mdi-format-list-bulleted"
                                   tooltip="Punkt-Aufzählung" @click="editor.chain().focus().toggleBulletList().run()"/>
          <WysiwygEditorToolbarBtn :active="editor.isActive('orderedList')" icon="mdi-format-list-numbered"
                                   tooltip="Nummerierte Aufzählung"
                                   @click="editor.chain().focus().toggleOrderedList().run()"/>
          <WysiwygEditorToolbarBtn :active="false" :disabled="!editor.can().liftListItem('listItem')"
                                   icon="mdi-format-indent-decrease"
                                   tooltip="Ebene zurück"
                                   @click="editor.chain().focus().liftListItem('listItem').run()"/>
          <WysiwygEditorToolbarBtn :active="false" :disabled="!editor.can().sinkListItem('listItem')"
                                   icon="mdi-format-indent-increase"
                                   tooltip="Listeneinschub"
                                   @click="editor.chain().focus().sinkListItem('listItem').run()"/>


        </v-btn-toggle>

        <v-divider
            class="mx-4"
            vertical
        ></v-divider>
        <WysiwygEditorToolbarBtn :active="imageDialog" icon="mdi-image"
                                 tooltip="Bild einfügen" @click="imageDialog=!imageDialog"/>
        <v-dialog v-model="imageDialog" max-width="800px">
          <ImageGallery v-if="parent" :parent="parent" collection="images" @imageClick="addImage"></ImageGallery>
          <v-alert v-else>
            Dateien können nur hochgeladen werden, wenn die Kampagne erstellt wurde.
          </v-alert>
        </v-dialog>

        <WysiwygEditorToolbarBtn :active="editor.isActive('link')" icon="mdi-link"
                                 tooltip="Link einfügen"
                                 @click="linkDialog = !linkDialog"
        />
        <v-dialog v-model="linkDialog" max-width="800px">
          <LinkBrowser :current-link="editor.getAttributes('link').href" :parent="parent"
                       @linkSelected="setLink"></LinkBrowser>
        </v-dialog>

        <WysiwygEditorToolbarBtn icon="mdi-minus" tooltip="Horizontale Linie"
                                 @click="editor.chain().focus().setHorizontalRule().run()"/>

        <!--        <WysiwygEditorToolbarBtn icon="mdi-comment-edit-outline" tooltip="Formular"
                                         @click="editor.chain().focus().setHorizontalRule().run()"/>-->

        <template>
          <div class="text-center">
            <v-menu>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>mdi-table</v-icon>
                    </v-btn>
                  </template>
                  <span>Tabelle</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item>
                  <WysiwygEditorToolbarBtn icon="mdi-table-plus" tooltip="Tabelle einfügen"
                                           @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"/>
                  <WysiwygEditorToolbarBtn icon="mdi-table-remove" tooltip="Tabelle löschen"
                                           @click="editor.chain().focus().deleteTable().run()"/>
                </v-list-item>
                <v-list-item>
                  <WysiwygEditorToolbarBtn icon="mdi-table-column-plus-before" tooltip="Spalte links einfügen"
                                           @click="editor.chain().focus().addColumnBefore().run()"/>

                  <WysiwygEditorToolbarBtn icon="mdi-table-column-plus-after" tooltip="Spalte rechts einfügen"
                                           @click="editor.chain().focus().addColumnAfter().run()"/>

                  <WysiwygEditorToolbarBtn icon="mdi-table-column-remove" tooltip="Spalte löschen"
                                           @click="editor.chain().focus().deleteColumn().run()"/>

                </v-list-item>
                <v-list-item>
                  <WysiwygEditorToolbarBtn icon="mdi-table-row-plus-before" tooltip="Reihe oben einfügen"
                                           @click="editor.chain().focus().addRowBefore().run()"/>

                  <WysiwygEditorToolbarBtn icon="mdi-table-row-plus-after" tooltip="Reihe unten einfügen"
                                           @click="editor.chain().focus().addRowAfter().run()"/>

                  <WysiwygEditorToolbarBtn icon="mdi-table-row-remove" tooltip="Reihe löschen"
                                           @click="editor.chain().focus().deleteRow().run()"/>

                </v-list-item>
                <v-list-item>
                  <WysiwygEditorToolbarBtn icon="mdi-table-merge-cells" tooltip="Zellen zusammenführen"
                                           @click="editor.chain().focus().mergeCells().run()"/>

                  <WysiwygEditorToolbarBtn icon="mdi-table-split-cell" tooltip="Zellen teilen"
                                           @click="editor.chain().focus().splitCell().run()"/>

                </v-list-item>
                <v-list-item>
                  <WysiwygEditorToolbarBtn icon="mdi-table-column" tooltip="Tabellen-Kopfspalte-ein/aus"
                                           @click="editor.chain().focus().toggleHeaderColumn().run()"/>

                  <WysiwygEditorToolbarBtn icon="mdi-table-row" tooltip="Tabellen-Kopfzeile-ein/aus"
                                           @click="editor.chain().focus().toggleHeaderRow().run()"/>

                  <WysiwygEditorToolbarBtn icon="mdi-page-layout-header" tooltip="Tabellen-Kopfzelle-ein/aus"
                                           @click="editor.chain().focus().toggleHeaderCell().run()"/>

                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>

        <WysiwygEditorToolbarBtn :disabled="hasForm" icon="mdi-form-textbox" tooltip="Dateneingabe einfügen"
                                 @click="editor.chain().focus().setForm().run()"/>


        <v-spacer/>

        <v-divider
            class="mx-4"
            vertical
        ></v-divider>

        <v-btn-toggle
            multiple
        >
          <WysiwygEditorToolbarBtn :disabled="!editor.can().undo()" icon="mdi-undo"
                                   tooltip="Schritt zurück"
                                   @click="editor.chain().focus().undo().run()"/>
          <WysiwygEditorToolbarBtn :disabled="!editor.can().redo()" icon="mdi-redo"
                                   tooltip="Schritt vorwärts"
                                   @click="editor.chain().focus().redo().run()"/>

        </v-btn-toggle>

        <WysiwygEditorToolbarBtn icon="mdi-format-clear" tooltip="Alle Formatierungen entfernen"
                                 @click="editor.chain().focus().clearNodes().unsetAllMarks().run()"
        />

      </v-toolbar>
      <v-card-text>
        <editor-content :editor="editor"/>

      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <v-card v-if="editor">
          <v-card-title>
            Vorschau
          </v-card-title>
          <v-card-text class="ProseMirror">
            <editor-content :editor="previewEditor"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import {Editor, EditorContent} from '@tiptap/vue-2'
//import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import History from '@tiptap/extension-history'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import {Indent} from "@/wysiwyg/indent"
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import {Color} from '@tiptap/extension-color'
import Blockquote from '@tiptap/extension-blockquote'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Gapcursor from '@tiptap/extension-gapcursor'
import Heading from '@tiptap/extension-heading'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'

import ImageGallery from "@/components/views/ImageGallery";
import WysiwygEditorToolbarBtn from "@/components/wysiwyg/WysiwygEditorToolbarBtn";
import LinkBrowser from "@/components/views/LinkBrowser";
import ExtensionEditForm from "@/components/wysiwyg/ExtensionEditForm";
//import { generateHTML } from '@tiptap/core'

const defaultFormModel = {
  vorname: {
    label: "Vorname",
    required: true,
    rules: "notEmptyRules",
    type: 'text'
  },
  nachname: {
    label: "Nachname",
    required: true,
    rules: "notEmptyRules",
    type: 'text'
  },
  email: "E-Mail",
};

const extensions = [
  //StarterKit,
  Document,
  Paragraph,
  Heading.configure({
    levels: [1, 2, 3],
  }),
  History,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Highlight.configure({multicolor: true}),
  Underline,
  BulletList,
  ListItem,
  OrderedList,
  Indent.configure({
    types: ['listItem', 'paragraph'],
    minLevel: 0,
    maxLevel: 8,
  }),
  Image,
  Dropcursor,
  Color,
  Text,
  TextStyle,
  Blockquote,
  HorizontalRule.configure({
    HTMLAttributes: {
      class: 'v-divider',
    },
  }),
  Subscript,
  Superscript,
  Link,
  Gapcursor,
  Table.configure({
    resizable: false,
  }),
  TableRow,
  TableHeader,
  TableCell,
  Bold,
  Italic,
];


export default {


  components: {
    EditorContent,
    WysiwygEditorToolbarBtn,
    ImageGallery,
    LinkBrowser,
  },

  props: {
    parent: {
      type: Object,
      required: false
    },
    wysiwygContent: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    previewContent() {
      return this.editor.getJSON()
    },
    editorHTML() {
      return this.editor.getHTML()
    },
    hasForm() {
      return this.editor.getHTML().indexOf('<tip-tap-vue-wrapper>') !== -1;
    }
  },
  data() {
    return {
      editor: null,
      previewEditor: null,
      imageDialog: false,
      linkDialog: false,
      colorDialog: false,
      pickerColor: null,
      highlightDialog: false,
      highlightColor: null,
      swatches: [
        [this.$vuetify.theme.currentTheme.primary],
        [this.$vuetify.theme.currentTheme.accent],
        [this.$vuetify.theme.currentTheme.secondary],
        ['none']
      ],
    }
  },
  methods: {
    setLink(link) {
      this.linkDialog = false;
      // cancelled
      if (link.href === null) {
        return
      }

      // empty
      if (link.href === '') {
        this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()

        return
      }

      // update link
      this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink(link)
          .run()
    },
    openColorPicker() {
      if (this.editor.getAttributes('textStyle').color === undefined) {
        this.pickerColor = this.$vuetify.theme.currentTheme.primary;
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.colorDialog = !this.colorDialog;
          })
        })
      } else {
        this.editor.chain().focus().unsetColor().run();
      }
    },
    updateColor(color) {
      this.editor.chain().focus().setColor(color.hex || color).run();
    },
    openHighlightPicker() {
      //no highlight set, open picker
      if (this.editor.getAttributes('highlight').color === undefined) {
        this.highlightColor = this.$vuetify.theme.currentTheme.secondary;
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.highlightDialog = !this.highlightDialog;
          })
        })
      } else {
        //highlight set. remove highlight
        this.editor.chain().focus().unsetHighlight().run();
      }
    },
    updateHighlightColor(color) {
      this.editor.chain().focus().toggleHighlight({color: color.hex || color}).run();
    },
    addImage(url) {
      if (url) {
        this.editor.chain().focus().setImage({src: url}).run()
      }
      this.imageDialog = false;
    },
  },
  watch: {
    wysiwygContent(wysiwygContent) {
      // HTML
      //const isSame = this.editor.getJSON() === wysiwygContent

      // JSON
      const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(wysiwygContent)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(wysiwygContent, false)
      this.previewEditor.commands.setContent(wysiwygContent, false)
    },
  },
  mounted() {

    //check if form model is defined, otherwise, load default model.
    extensions.push(ExtensionEditForm.configure({
      HTMLAttributes: {
        model: typeof this.$props.wysiwygContent === 'object' && this.$props.wysiwygContent.formModel ? this.$props.wysiwygContent.formModel : defaultFormModel
      }
    }));

    this.editor = new Editor({
      content: this.wysiwygContent,
      extensions: extensions,
      onUpdate: () => {
        // HTML
        //const editorHtml = this.editor.getHTML();
        //this.$emit('input', editorHtml)
        //this.previewEditor.commands.setContent(editorHtml, false);

        // JSON
        const editorJSON = this.editor.getJSON();
        this.$emit('input', editorJSON);
        this.previewEditor.commands.setContent(editorJSON, false);
      },
    });
    this.previewEditor = new Editor({
      content: this.previewContent,
      editable: false,
      extensions: extensions
    })
  },

  beforeDestroy() {
    this.editor.destroy()
    this.previewEditor.destroy();
  }
}
</script>
<style>
.ProseMirror:focus-visible {
  border: 0 solid black;
  outline-color: transparent;
  outline: none;
}


</style>

<style lang="scss">
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: var(--v-primary-base);
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>