<template>
  <v-card>
    <v-card-title>
      Hyperlink konfigurieren
    </v-card-title>
    <v-card-text>

      <v-tabs v-model="linkTypeIndex" background-color="primary">
        <v-tab
            v-for="option in types"
            :key="option"
        >
          {{ option }}
        </v-tab>
      </v-tabs>

      <EditForm v-model="link" :model="editLinkModel"/>
      <ImageGallery v-if="linkType==='Datei' && parent" :ref-id="parent.id" :refName="parent.__typename" files
                    @imageClick="setFile"/>
      <v-alert v-else-if="linkType==='Datei'">
        Dateien können nur hochgeladen werden, wenn die Kampagne erstellt wurde.
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn
          color="accent"
          @click="setLink"
      >
        Übernehmen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import navStructure from "@/config/nav/navStructure";

export default {
  components: {
    EditForm: () => import('@/components/Forms/EditForm'),
    ImageGallery: () => import('@/components/views/ImageGallery')
  },
  props: {
    "currentLink": String,
    parent: {
      type: Object,
      required: false
    },
  },
  name: 'LinkBrowser',
  data() {
    return {
      linkTypeIndex: 0,
      types: ['Intern', 'Extern', 'Datei'],
      link: {
        href: null,
        target: "_blank",
        download: false
      },

    };
  },
  computed: {
    linkType() {
      return this.types[this.linkTypeIndex];
    },
    editLinkModel() {
      const models = {
        Intern: {
          href: {
            label: "Linkziel",
            type: "autocomplete",
            options: this ? this.availablePages : [],
          },
          target: {
            type: "select",
            options: ['_blank', "_self"]
          }
        },
        Extern: {
          href: {
            label: "Link-URL",
            type: "text"
          },
          target: {
            type: "select",
            options: ['_blank', "_self"]
          }
        },
        Datei: {
          href: {
            label: "Datei-URL",
            type: "text"
          },
          /*download:{
            label: "Download erzwingen?",
            type: "checkbox"
          },*/
          target: {
            type: "select",
            options: ['_blank', "_self"]
          }
        }
      };
      return models[this.linkType]
    },
    availablePages() {
      /*
      {title: 'Mein Verein', path: '/verein', icon: 'mdi-contact-mail', visible: 'isVerein'},
       */
      return navStructure.map(navItem => {
        return {
          text: navItem.title,
          value: navItem.path
        }
      });
    }
  },
  methods: {
    setFile(url) {
      this.link.href = url;
    },
    setLink() {
      this.$emit('linkSelected', this.link);
    },
  }
}
</script>
