<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
          :class="{
              'v-btn--active': active
            }"
          :disabled="disabled"
          icon
          small
          @click="$emit('click')"
          v-on="on"
      >
        {{ btnTxt }}
        <v-icon v-if="icon">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <template>{{ tooltip }}</template>
  </v-tooltip>
</template>

<script>
export default {
  name: "EditorToolbarBtn",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
    },
    tooltip: {
      type: String
    },
    btnTxt: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
